/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "44bits 팟캐스트 117번째 로그에서는 버크셔혜서웨이 주식 거래 중지, 카카오톡 장애, 개인 프로젝트 개발 스택에 대해서 이야기를 나누었습니다."), "\n", React.createElement(_components.h3, null, "버크셔혜서웨이 A 주식 거래 중지"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.wsj.com/articles/berkshire-hathaways-stock-price-is-too-much-for-computers-11620168548"
  }, "Berkshire Hathaway’s Stock Price Is Too Much for Computers - WSJ")), "\n"), "\n", React.createElement(_components.h3, null, "Homebrew 보안 취약점 발견"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.dailysecu.com/news/articleView.html?idxno=123521"
  }, "맥OS, 리눅스의 패키지 매니져인 Homebrew, 치명적 RCE 취약점 발견 - 데일리시큐")), "\n"), "\n", React.createElement(_components.h3, null, "카카오톡 장애"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.sedaily.com/NewsVIew/22M967FM4D"
  }, "2시간 넘는 카톡 장애...이용자 불편에 인증 서비스 긴급전환도 - 서울경제")), "\n"), "\n", React.createElement(_components.h3, null, "네이버 블로그 이벤트 조기 중단"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://namu.wiki/w/%EB%84%A4%EC%9D%B4%EB%B2%84%20%EB%B8%94%EB%A1%9C%EA%B7%B8%20%EC%98%A4%EB%8A%98%EC%9D%BC%EA%B8%B0%20%EC%A1%B0%EA%B8%B0%EC%A2%85%EB%A3%8C%20%EC%82%AC%ED%83%9C"
  }, "네이버 블로그 오늘일기 조기종료 사태 - 나무위키")), "\n"), "\n", React.createElement(_components.h3, null, "개인 프로젝트 개발 스택"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://mysetting.io/"
  }, "mysetting.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vercel.com/"
  }, "Develop. Preview. Ship. For the best frontend teams – Vercel")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://supabase.io/"
  }, "The Open Source Firebase Alternative | Supabase")), "\n"), "\n", React.createElement(_components.h3, null, "애플 M1 맥북에어"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/macbook-air/"
  }, "MacBook Air - Apple")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
